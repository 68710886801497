<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>
    
    <div class="content-container">
      <h1>Shenzhen Furniture Association</h1>
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/organizer1.png" alt="" class="block">
      <div class="content">
        The Shenzhen Furniture Association, established in 1986. 458 members make up the association covering major industry sectors including furniture manufacturers, raw materials suppliers, producers of furniture accessories, furniture retailers, furniture stores, interior design firms, furniture lighting companies, and woodworking machinery manufacturers.
      </div>
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/organizer2.png" alt="" class="block">
      <div class="content">
        The Shenzhen Furniture Association (SZFA) serves as a market-oriented service provider for industry related enterprises, striving to become the core service organization of China's furniture industry. 
      </div>
      <br />
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/organizer3.png" alt="" class="block">
      <br />
      <div class="content">
        The Shenzhen Furniture Association collaborates closely with various entities, including the Shenzhen Furniture Design Institute, Desy Exhibition, Saide Testing, Supply Chain Management and Shenzhen Institute of Standards and Technology. Through these collaborations, the association integrates resources along the entire furniture industry chain, offering a unique and comprehensive range of domestic services, including research and development, education, exhibitions, testing, and supervision. 
      </div>
      <div class="video-container relative">
        <video id="my-video" controls class="fill">
          <source src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/SZFA23_promo_EN_AI_dummy.mp4" type="video/mp4">
        </video>
        <!-- 播放按钮 -->
        <!-- <div v-if="showMask" class="play-btn-box absolute flex justify-center align-center">
          <img src="../assets/img/play2.png" alt="" class="play-btn" @click="playVideo">
        </div> -->
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen Furniture Association - Organizer History | Estd. in 1986', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: '458 Members Make Up the Shenzhen Furniture Association, Established in 1986, Covering Major Industry Sectors. SZFA Serves as Market-Oriented Service Provider.'},
    ],
  },
  data() {
    return {
      current: 'competition',
      showMask: true,
    }
  },
  created() {

  },
  destroyed() {
    
  },
  mounted() {
    // this.video = document.getElementById("my-video");
    // this.video.addEventListener('pause', () => { // 视频暂停时
    //   this.showMask = true;
    // });
    // this.video.addEventListener('ended', () => { // 视频播放完毕时
    //   this.showMask = true;
    // });
  },
  methods: {
    playVideo() {
      this.video.play();
      this.showMask = false;
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    
    h1 {
      font-size: 20px;
      margin: 10px 0;
    }
    h2 {
      font-size: 18px;
      margin: 20px 0 0;
    }
    
    .video-container {
      width: 355px;
      height: 200px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      margin: 20px auto;
      
      .play-btn-box {
        display: none;
      }
    }
    
    .content {
      width: 355px;
      font-size: 14px;
      margin: 15px 0;
      text-align: left;
    }
    
    img {
      width: 354px;
      height: auto;
      border-radius: 4px;
    }
  }
  
}

// pc端
@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 10px auto 20px;
    text-align: center;
    
    h1 {
      font-size: 6px;
      margin-bottom: 4px;
    }
    
    h2 {
      font-size: 5px;
      margin-top: 10px;
    }
    
    .video-container {
      width: 240px;
      height: 136px;
      background-color: #fff;
      border-radius: 2px;
      overflow: hidden;
      margin: 10px auto;
      
      .play-btn-box {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, .3);
      }
      .play-btn {
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    
    .content {
      width: 240px;
      font-size: 4px;
      margin: 5px auto;
      text-align: left;
    }
    
    img {
      width: 240px;
      height: auto;
      margin: 0 auto;
      border-radius: 2px;
    }
  }
  
}
</style>